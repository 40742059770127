module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bitcoin.com","short_name":"Bitcoin.com","start_url":"/","display":"fullscreen","background_color":"#fdfdfd","theme_color":"#111724","icon":"static/images/favicon.png","icons":[{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"839c20236a994c437231f424e0f6730a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Gilroy:n4,n6,n7"],"urls":["https://menu.cdn.bitcoindotcom.net/uni/dist/fonts.css","/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Bitcoin.com Jobs | Content Manager","htmlFavicon":"/home/runner/work/bitcoincom-tools/bitcoincom-tools/static/icons/icon-512x512.png","manualInit":true,"modulePath":"/home/runner/work/bitcoincom-tools/bitcoincom-tools/src/cms/cms.js"},
    }]
