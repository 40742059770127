// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cash-address-converter-js": () => import("./../../../src/pages/cash-address-converter.js" /* webpackChunkName: "component---src-pages-cash-address-converter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-slp-dividend-calculator-js": () => import("./../../../src/pages/slp-dividend-calculator.js" /* webpackChunkName: "component---src-pages-slp-dividend-calculator-js" */),
  "component---src-pages-verify-message-js": () => import("./../../../src/pages/verify-message.js" /* webpackChunkName: "component---src-pages-verify-message-js" */),
  "component---src-pages-widgets-js": () => import("./../../../src/pages/widgets.js" /* webpackChunkName: "component---src-pages-widgets-js" */)
}

